import React from "react";
import "./App.css";

import Home from "./components/Home/Home";
import LatestWorkSinglePage from "./components/LatestWorkSinglePage/LatestWorkSinglePage";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./ScrollToTop";

import { BrowserRouter as Router, Route } from "react-router-dom";

function App() {
  return (
    <Router basename="/">
      <ScrollToTop>
        <div className="App">
          <Route exact path="/" component={Home} />
          <Route path="/work/:id" component={LatestWorkSinglePage} />
        </div>
        <Footer />
      </ScrollToTop>
    </Router>
  );
}

export default App;
