import React from "react";
import "./Home.scss";

import Header from "..//Header/Header";
import LatestWork from "../LatestWork/LatestWork";
import About from "../About/About";
import Skills from "../Skills/Skills";

const home = () => {
  return (
    <div>
      <Header />
      <div className="container">
        <h1 className="intro-title">
          I’m a <u>creative</u>,<br />
          <u>reliable</u> and <u>professional</u>
          <br />
          Front End Developer.
          <br />
          I’m available to work in
          <br />
          North Jersey and Manhattan.
        </h1>
        <LatestWork />
        <About />
        <Skills />
      </div>
    </div>
  );
};

export default home;
