import React from "react";
import "./WorkList.scss";

import WorkData from "../../Data/LatestWorkData/LatestWorkData";

import Work from "./Work";

class WorkList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      works: WorkData
    };
  }
  render() {
    return (
      <ul className="work-list">
        {this.state.works.map(work => (
          <Work
            id={work.id}
            title={work.title}
            thumb={work.thumb}
            involvement={work.involvement}
          />
        ))}
      </ul>
    );
  }
}

export default WorkList;
