import React from "react";
import { Link } from "react-router-dom";

const Work = props => {
  return (
    <li>
      <Link to={`/work/${props.id}`}>
        <img src={props.thumb} alt={props.title} />
        <h3>{props.title}</h3>
        <p>{props.involvement}</p>
      </Link>
    </li>
  );
};

export default Work;
