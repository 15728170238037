import React, { Component } from "react";
import "./LatestWorkSinglePage.scss";

import WorkData from "../../Data/LatestWorkData/LatestWorkData";
import HeaderNotHome from "../Header/HeaderNotHome";

class LatestWorkSinglePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      work: WorkData[this.props.match.params.id - 1],
    };
  }

  renderWorkDetail = ({ work }) => {
    const { title, description, industry, urLLink, img, urlName } = work;

    return (
      <div>
        <HeaderNotHome />
        <div className="work-single-page container">
          <h2>{title}</h2>
          <div className="work-single-page__paragraph">
            <p>{description[0]}</p>
            <p>{description[1]}</p>
            <p>{description[2]}</p>
          </div>
          <span>{industry}</span>
          <span>
            {" "}
            <a href={urLLink} target="_blank" rel="noopener noreferrer">
              {urlName}
            </a>
          </span>

          <img src={img} alt={title} />
        </div>
      </div>
    );
  };

  render() {
    return <div>{this.renderWorkDetail(this.state)}</div>;
  }
}

export default LatestWorkSinglePage;
