import React from "react";
import "./Skills.scss";

const skills = () => {
  return (
    <section className="skills" id="skills">
      <h2>Skills</h2>
      <div className="skills__content">
        <div>
          <h3>Web Development</h3>
          <ul>
            <li>HTML, Jade, CSS, SASS/SCSS</li>
            <li>JavaScript, jQuery, React.js</li>
            <li>Node with Express (basics) </li>
            <li>Responsive Web Design, Bootstrap</li>
            <li>WordPress, WooCommerce</li>
            <li>Webpack</li>
            <li>Git</li>
            <li>VS Code, Sublime</li>
            <li>Photoshop, Adobe XD, Sketch</li>
          </ul>
        </div>
        <div className="skills__content__lang">
          <h3>Languages I speak</h3>
          <ul>
            <li>
              <img src="./img/flags/france.png" alt="French Flag" /> French
              native
            </li>
            <li>
              <img src="./img/flags/united-states.png" alt="USA Flag" /> English
              fluent
            </li>
            <li>
              <img src="./img/flags/portugal.png" alt="Portuguese Flag" />{" "}
              Portuguese fluent
            </li>
            <li>
              <img src="./img/flags/germany.png" alt="German Flag" /> German
              fluent
            </li>
            <li>
              <img src="./img/flags/luxembourg.png" alt="French Flag" />{" "}
              Luxemburgish fluent
            </li>
            <li>
              <img src="./img/flags/spain.png" alt="Spain Flag" /> Spanish
              conversational
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default skills;
