import React from "react";
import "./LatestWork.scss";

import WorkList from "./WorkList";

const latestwork = () => {
  return (
    <section className="work" id="work">
      <h2>LATEST WORK</h2>
      <WorkList />
    </section>
  );
};

export default latestwork;
