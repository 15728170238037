import React from "react";
import "./About.scss";

const about = () => {
  return (
    <section className="about" id="about">
      <h2>About</h2>
      <p className="p--first">
        My name is Filipe Valente and I’m a Front End Developer. I am of
        Portuguese decent born in Luxembourg (no, it is not a city in Germany,
        it is actually a very small, but very rich country right in the middle
        of Europe). I moved to the United States in 2005. In 2007 I started my
        front end developer journey and have loved every moment of it .
      </p>
      <div className="about__content">
        <img src="./img/profile-pic.jpg" alt="Profile" />
        <div>
          <p>
            I am very passionate about my work, and will make a case for what I
            believe in especially if it’s for the benefit of my clients. My
            coding style is clean and efficient. I absolutely love what I do.
          </p>
          <p>
            I am what you call a “metrosexual geek”. I love technology. I like
            to keep up to date with the latest and greatest of the industry.
            However, on any given Sunday you can find me watching a documentary.
            Learning is growing and I am constantly doing so.
          </p>
        </div>
      </div>
    </section>
  );
};

export default about;
