import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";

import Scrollchor from "react-scrollchor";

const headernothome = () => {
  return (
    <header className="header">
      <Link to="/" className="logo">
        filipe valente
      </Link>
      <nav>
        <ul>
          <li>
            <a href="/#work">work</a>
          </li>
          <li>
            <a href="/#about">about</a>
          </li>
          <li>
            <a href="/#skills">skills</a>
          </li>
          <li>
            <Scrollchor to="contact">contact</Scrollchor>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default headernothome;
