import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import Scrollchor from "react-scrollchor";

const header = () => {
  return (
    <header className="header">
      <Link to="/" className="logo">
        filipe valente
      </Link>
      <nav>
        <ul>
          <li>
            <Scrollchor to="#work">work</Scrollchor>
          </li>
          <li>
            <Scrollchor to="#about">about</Scrollchor>
          </li>
          <li>
            <Scrollchor to="#skills">skills</Scrollchor>
          </li>
          <li>
            <Scrollchor to="#contact">contact</Scrollchor>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default header;
