import React from "react";
import "./Footer.scss";

const footer = () => {
  return (
    <footer className="footer" id="contact">
      <div className="container">
        <h2>Let's connect</h2>
        <div className="footer__content">
          <ul>
            <li>
              <h3>Social</h3>
              <a
                href="https://www.linkedin.com/in/filipevalentegomes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fab fa-linkedin"></i> LinkedIn
              </a>
            </li>
            <li>
              <h3>Resume</h3>
              <a
                href="/docs/FilipeValenteResume2019.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fas fa-file-pdf"></i> Download PDF
              </a>
            </li>
            <li>
              <h3>Say Hi!</h3>
              <a href="mailto:info@filipevalentegomes.com" target="_top">
                <i class="fas fa-envelope"></i> info@filipevalentegomes.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default footer;
